export default [
  {
    icon: '📚',
    title: 'PPAP',
    description: 'PPAP - PPA PPTI Alumni Portal',
  },
  {
    icon: '🏪',
    title: 'PiKA',
    source: 'https://github.com/njsgan/PiKA',
    description: 'An open source Point of Sales (POS) app built with java and mysql',
  },
  {
    icon: '📖',
    title: 'KOSAKATA',
    description: 'Indonesian language online learning platform with quizzes',
  },
  {
    icon: '🏫',
    title: 'Wardaya Online',
    path: 'https://wardayaonline.com',
    description: 'Wardaya College online learning platform',
  },
]
