import React from 'react'
import GitHubButton from 'react-github-btn'

export default function Projects({ data }) {
  return (
    <div className="projects">
      {data.map((node) => {
        return (
          <div className="project " key={node.title}>
            <div>
              <a>
                <div className="icon">{node.icon}</div>
                <h3>{node.title}</h3>
              </a>
              <div className="description">{node.description}</div>
            </div>
            <div className="flex">
              {node.source && (
                <GitHubButton href={node.source} data-size="large" data-show-count="true">
                  Source
                </GitHubButton>
              )}
              {node.path && (
                <a className="button" href={node.path} target="_blank" rel="noopener noreferrer">
                  Link
                </a>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
