import React from 'react'

let titleStyle = {
  marginLeft: '10px',
  marginBottom: '12px'
};

export default function Projects({ data }) {
  return (
    <div className="projects">
      {data.map((node) => {
        return (
          <div className="project " key={node.title}>
            <div>
              <a
                href={node.path || node.source}
                key={node.path || node.source}
                target="_blank"
                rel="noreferrer"
              >
                <div className="icon"><img src={node.img} alt={node.title}/></div>
                <h3 style={titleStyle}>{node.title}</h3>
              </a>
            </div>
          </div>
        )
      })}
    </div>
  )
}

