import microsoft from '../../content/images/microsoft.svg'
import eccouncil from '../../content/images/eccouncil.png'
import coursera from '../../content/images/coursera.jpg'
import qwiklabs from '../../content/images/qwiklabs.png'
import icsi from '../../content/images/icsi.png'
import ceh from '../../content/images/ceh.png'
import chfi from '../../content/images/chfi.png'

export default [
  {
    title: 'Certified Ethical Hacker (C|EH)',
    path: 'https://aspen.eccouncil.org/VerifyBadge?type=certification&a=DYxz6RaoUlbU0zRGhNOhKDBqlAmi6sk7zd0HuR2Cwjg=',
    img: eccouncil,
  },
  {
    title: 'Computer Hacking Forensic Investigator (CHFI)',
    path: 'https://aspen.eccouncil.org/VerifyBadge?type=certification&a=2jEor3aEs1aioqtEUnyEK5a2UPwrTtOUhPp5QSiLOmA=',
    img: eccouncil,
  },
  {
    title: 'Exam 483: Programming in C#',
    path: 'https://www.youracclaim.com/badges/f961a793-49cf-4283-9b32-784a07dd5c97/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'MCSA: BI Reporting - Certified 2019',
    path: 'https://www.youracclaim.com/badges/6859ccbc-267a-4d9f-968b-9ca6e939bdf1/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'MCSE: Data Management and Analytics — Certified 2020',
    path: 'https://www.youracclaim.com/badges/9d0017f8-d994-464b-a1fb-4c219f2af3ca/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'MCSA: Universal Windows Platform - Certified 2020',
    path: 'https://www.youracclaim.com/badges/3b44cb74-58e6-424d-bf97-b3a8952de21c/linked_in_profile',
    img: microsoft,
  },
  {
    title: 'ICSI | CNSS Certified Network Security Specialist',
    path: 'https://www.credential.net/41d90331-4b90-4e39-9524-e30b8aef2bc0',
    img: icsi,
  }
]
